<script setup lang="ts">
import { ref } from 'vue'
import ManualFundModal from './ManualFundModal.vue'
import { ModalsContainer } from 'vue-final-modal'

const props = defineProps<{ ase: any }>()
const modalState = ref(false)

const openManualFundModal = () => {
  let ase = props.ase

  modalState.value = true
}
</script>

<template>
  <div>
    <span
      class="block px-3 py-1 text-sm leading-6 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
      @click="openManualFundModal"
    >
      Manual Fund
    </span>

    <ManualFundModal
      v-model="modalState"
      :ase="ase"
    />
    <ModalsContainer />
  </div>
</template>
