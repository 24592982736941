<script setup lang="ts">
import { onMounted, ref } from "vue"

import {
  type IQueryFacebookConceptStatsResponse,
  queryFacebookConceptStats,
} from "@http/FacebookStats"
import DotLoader from "@components/general/DotLoader.vue"
import { formatCurrency, formatNumber } from "@utils"

const props = defineProps<{
  conceptId: number
}>()

const loaded = ref<boolean>(false)
const conceptStats = ref<IQueryFacebookConceptStatsResponse>()
onMounted(async () => {
  conceptStats.value = await queryFacebookConceptStats(props.conceptId)
  loaded.value = true
})

const currency = formatCurrency
const number = formatNumber
</script>

<template>
  <div class="space-y-10">
    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900">Last Month</h3>
      <dl class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Total Brand Exposures</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ number(conceptStats!.lastMonth.impressions) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 70,946</span> -->
            </div>
  
            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              12%
            </div> -->
          </dd>
        </div>
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Clicks</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ number(conceptStats!.lastMonth.clicks) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 56.14%</span> -->
            </div>

            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              2.02%
            </div> -->
          </dd>
        </div>
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Cost Per Click</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ currency(conceptStats!.lastMonth.cpc, true) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 56.14%</span> -->
            </div>

            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              2.02%
            </div> -->
          </dd>
        </div>
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Cost Per 1k Impressions</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ currency(conceptStats!.lastMonth.cpm, true) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 56.14%</span> -->
            </div>

            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              2.02%
            </div> -->
          </dd>
        </div>
      </dl>
    </div>

    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900">Year-To-Date</h3>
      <dl class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Total Brand Exposures</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ number(conceptStats!.yearToDate.impressions) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 70,946</span> -->
            </div>
  
            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              12%
            </div> -->
          </dd>
        </div>
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Clicks</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ number(conceptStats!.yearToDate.clicks) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 56.14%</span> -->
            </div>

            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              2.02%
            </div> -->
          </dd>
        </div>
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Cost Per Click</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ currency(conceptStats!.yearToDate.cpc, true) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 56.14%</span> -->
            </div>

            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              2.02%
            </div> -->
          </dd>
        </div>
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Cost Per 1k Impressions</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ currency(conceptStats!.yearToDate.cpm, true) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 56.14%</span> -->
            </div>

            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              2.02%
            </div> -->
          </dd>
        </div>
      </dl>
    </div>

    <div>
      <h3 class="text-base font-semibold leading-6 text-gray-900">All Time</h3>
      <dl class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Total Brand Exposures</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ number(conceptStats!.allTime.impressions) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 70,946</span> -->
            </div>
  
            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              12%
            </div> -->
          </dd>
        </div>
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Clicks</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ number(conceptStats!.allTime.clicks) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 56.14%</span> -->
            </div>

            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              2.02%
            </div> -->
          </dd>
        </div>
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Cost Per Click</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ currency(conceptStats!.allTime.cpc, true) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 56.14%</span> -->
            </div>

            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              2.02%
            </div> -->
          </dd>
        </div>
        <div class="px-4 py-5 sm:p-6 flex flex-col justify-between">
          <dt class="text-base font-normal text-gray-900">Cost Per 1k Impressions</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <DotLoader v-if="!loaded" class="h-[32px]" />
            <div v-else class="flex items-baseline h-[32px] text-2xl font-semibold text-brand-orange">
              {{ currency(conceptStats!.allTime.cpm, true) }}
              <!-- <span class="ml-2 text-sm font-medium text-gray-500">from 56.14%</span> -->
            </div>

            <!-- <div class="inline-flex items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800 md:mt-2 lg:mt-0">
              <svg class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
              </svg>
              <span class="sr-only"> Increased by </span>
              2.02%
            </div> -->
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>