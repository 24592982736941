<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 20" preserveAspectRatio="xMidYMid" width="120" height="20" style="shape-rendering: auto; display: block; background: transparent;" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g>
    <circle fill="#219ebc" r="2" cy="10" cx="84">
      <animate begin="0s" keySplines="0 0.5 0.5 1" values="7;0" keyTimes="0;1" calcMode="spline" dur="0.3333333333333333s" repeatCount="indefinite" attributeName="r"></animate>
      <animate begin="0s" values="#219ebc;#fb8500;#ffb703;#023047;#219ebc" keyTimes="0;0.25;0.5;0.75;1" calcMode="discrete" dur="1.3333333333333333s" repeatCount="indefinite" attributeName="fill"></animate>
    </circle>
    <circle fill="#219ebc" r="2" cy="10" cx="16">
      <animate begin="0s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;7;7;7" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.3333333333333333s" repeatCount="indefinite" attributeName="r"></animate>
      <animate begin="0s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.3333333333333333s" repeatCount="indefinite" attributeName="cx"></animate>
    </circle>
    <circle fill="#023047" r="2" cy="10" cx="50">
      <animate begin="-0.3333333333333333s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;7;7;7" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.3333333333333333s" repeatCount="indefinite" attributeName="r"></animate>
      <animate begin="-0.3333333333333333s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.3333333333333333s" repeatCount="indefinite" attributeName="cx"></animate>
    </circle>
    <circle fill="#ffb703" r="2" cy="10" cx="84">
      <animate begin="-0.6666666666666666s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;7;7;7" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.3333333333333333s" repeatCount="indefinite" attributeName="r"></animate>
      <animate begin="-0.6666666666666666s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.3333333333333333s" repeatCount="indefinite" attributeName="cx"></animate>
    </circle>
    <circle fill="#fb8500" r="2" cy="10" cx="16">
      <animate begin="-1s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="0;0;7;7;7" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.3333333333333333s" repeatCount="indefinite" attributeName="r"></animate>
      <animate begin="-1s" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" calcMode="spline" dur="1.3333333333333333s" repeatCount="indefinite" attributeName="cx"></animate>
    </circle>
    <g></g>
  </g>
</svg>
</template>