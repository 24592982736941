import http from '@http/apiProvider'

export type IFundAdStrategyExecutionParams = {
  adStrategyExecutionId: number,
  amount: number
}

export async function fundAdStrategyExecution (params: IFundAdStrategyExecutionParams) {
  const response = await http.post(`/ad_strategy_executions/${params.adStrategyExecutionId}/fund`, {
    amount: params.amount
  });
  return response.data;
}
