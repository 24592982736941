<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import { VueFinalModal } from 'vue-final-modal'

const props = defineProps<{ modelValue: boolean; subscription: any }>()
const emit = defineEmits(['update:modelValue'])

const closeModal = () => {
  emit('update:modelValue', false)
}

const formatCurrency = (amount: number) => {
  return `$${(amount / 100).toFixed(2)}`
}

const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString()
}
</script>

<template>
  <VueFinalModal
    v-model="props.modelValue"
    class="flex justify-center items-center"
    content-class="flex flex-col p-4 sm:p-8 bg-white rounded-lg w-[95%] h-[95vh] relative"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    clickToClose
    @update:modelValue="closeModal"
  >
    <!-- Sticky Close Button at the top right -->
    <div class="sticky top-0 right-0 flex justify-end bg-white z-10">
      <button type="button" @click="closeModal" class="text-gray-500 text-2xl">×</button>
    </div>

    <!-- Scrollable content container -->
    <div class="flex flex-col overflow-y-auto h-[calc(95vh-3rem)] pr-4 space-y-8">
      <!-- Main content section -->
      <div>
        <!-- Subscription and Customer Details -->
        <div class="mb-4 text-gray-700">
          <h2 v-if="props.subscription.subscription_name" class="text-xl font-semibold text-gray-900">
            {{ props.subscription.subscription_name }}
          </h2>
          <p class="text-sm"><small>Subscription ID: {{ props.subscription.stripe_subscription_id }}</small></p>
          <p class="text-sm"><strong>Customer Name:</strong> {{ props.subscription.stripe_customer_name }}</p>
          <p class="text-sm"><small>Customer ID: {{ props.subscription.stripe_customer_id }}</small></p>
          <p class="text-sm"><strong>Started Date:</strong> {{ props.subscription.subscription_start_date }}</p>
          <p class="text-sm"><strong>Current Period:</strong> {{ props.subscription.subscription_current_period.start }} - {{ props.subscription.subscription_current_period.end }}</p>
        </div>
        <hr class="border-gray-300" />

        <!-- Pricing Section with Line Items and Coupon -->
        <div class="mt-6">
          <h3 class="text-lg font-semibold text-gray-900">Pricing</h3>
          <hr class="my-2 border-gray-300" />
          <table class="w-full mb-4">
            <thead>
              <tr class="text-left text-sm font-semibold text-gray-900">
                <th class="py-2 align-middle w-[70%]">Name</th>
                <th class="py-2 align-middle text-center w-[15%]">Qty</th>
                <th class="py-2 align-middle text-right w-[15%]">Total</th>
              </tr>
            </thead>
            <tbody class="border-b border-gray-300">
              <tr v-for="item in props.subscription.line_items" :key="item.name" class="text-sm text-gray-600">
                <td class="py-2 align-middle w-[70%]">{{ item.name }}</td>
                <td class="py-2 align-middle text-center w-[15%]">x {{ item.quantity }}</td>
                <td class="py-2 align-middle text-right w-[15%]"><strong>{{ formatCurrency(item.total) }}</strong></td>
              </tr>
              <tr v-if="props.subscription.coupon && (props.subscription.coupon.amount_off || props.subscription.coupon.percent_off)" class="text-sm text-gray-600">
                <td class="py-2 align-middle font-bold">
                  Coupon: {{ props.subscription.coupon.name || 'Discount' }}
                </td>
                <td class="py-2"/>
                <td class="py-2 align-middle text-right font-bold">
                  <strong>
                    {{ 
                      props.subscription.coupon.amount_off 
                        ? formatCurrency(props.subscription.coupon.amount_off) 
                        : props.subscription.coupon.percent_off + '%'
                    }}
                  </strong>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="text-sm font-semibold text-gray-900">
                <td colspan="2"/>
                <td class="align-middle text-right">Subtotal: {{ formatCurrency(props.subscription.subtotal) }}</td>
              </tr>
              <tr  class="text-sm font-semibold text-gray-900">
                <td colspan="2"/>
                <td class="align-middle text-right">
                  Discount:
                  <span v-if="props.subscription.discount_amount !== 0">
                    -{{ formatCurrency(props.subscription.discount_amount) }}
                  </span>
                  <span v-else>
                    {{ formatCurrency(props.subscription.discount_amount) }}
                  </span>
                </td>
              </tr>
              <tr class="text-sm font-semibold text-gray-900">
                <td colspan="2"/>
                <td class="align-middle text-right">Total: {{ formatCurrency(props.subscription.total) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <hr class="border-gray-300" />

        <!-- Upcoming Invoice Section -->
        <div class="mt-6">
          <h3 class="text-lg font-semibold text-gray-900">Upcoming Invoice</h3>
          <hr class="my-2 border-gray-300" />
          <p class="text-sm text-gray-700"><strong>Invoice Date Range:</strong> {{ formatDate(props.subscription.upcoming_invoice.date_range.start) }} - {{ formatDate(props.subscription.upcoming_invoice.date_range.end) }}</p>
          <p class="text-sm text-gray-700"><strong>Processed on:</strong> {{ formatDate(props.subscription.upcoming_invoice.date_processed) }}</p>
          <table class="w-full mt-2 mb-4">
            <thead>
              <tr class="text-left text-sm font-semibold text-gray-900">
                <th class="py-2 align-middle w-[70%]">Name</th>
                <th class="py-2 align-middle text-center w-[15%]">Qty</th>
                <th class="py-2 align-middle text-right w-[15%]">Total</th>
              </tr>
            </thead>
            <tbody class="border-b border-gray-300">
              <tr v-for="item in props.subscription.upcoming_invoice.line_items" :key="item.description" class="text-sm text-gray-600">
                <td class="py-2 align-middle w-[70%]">{{ item.description }}</td>
                <td class="py-2 align-middle text-center w-[15%]">x {{ item.quantity }}</td>
                <td class="py-2 align-middle text-right w-[15%]">{{ formatCurrency(item.amount) }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="text-sm font-semibold text-gray-900">
                <td colspan="2"/>
                <td class="align-middle text-right">Subtotal: {{ formatCurrency(props.subscription.upcoming_invoice.subtotal) }}</td>
              </tr>
              <tr class="text-sm font-semibold text-gray-900">
                <td colspan="2"/>
                <td class="align-middle text-right">Total: {{ formatCurrency(props.subscription.upcoming_invoice.total) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <!-- Past Invoices section below -->
      <div>
        <h3 class="text-lg font-semibold text-gray-900">Past Invoices</h3>
        <hr class="my-2 border-gray-300" />
        <table class="w-full text-sm text-gray-600">
          <thead>
            <tr class="text-left font-semibold text-gray-900">
              <th class="py-2 align-middle">Amount</th>
              <th class="py-2 align-middle">Status</th>
              <th class="py-2 align-middle">Inv#</th>
              <th class="py-2 align-middle">Created</th>
              <th class="py-2 align-middle">Note</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invoice in props.subscription.past_invoices" :key="invoice.number" class="even:bg-gray-50">
              <td class="py-2 align-middle">{{ formatCurrency(invoice.amount_paid) }}</td>
              <td class="py-2 align-middle">
                <span 
                  :class="{
                    'bg-blue-200 text-blue-800': invoice.status === 'draft',
                    'bg-yellow-200 text-yellow-800': invoice.status === 'open',
                    'bg-green-200 text-green-800': invoice.status === 'paid',
                    'bg-gray-200 text-gray-800': invoice.status === 'void',
                    'bg-red-200 text-red-800': invoice.status === 'uncollectible',
                  }"
                  class="px-2 py-1 rounded-full text-xs font-semibold"
                >
                  {{ invoice.status }}
                </span>
              </td>
              <td class="py-2 align-middle">{{ invoice.number }}</td>
              <td class="py-2 align-middle">{{ invoice.created_at }}</td>
              <td class="py-2 align-middle">{{ invoice.note }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </VueFinalModal>
</template>
