<script setup lang="ts">
import { computed } from "vue"
import { formatCurrency, formatNumber } from "@utils/index"

import DotLoader from "@components/general/DotLoader.vue"

const props = withDefaults(defineProps<{
  label: string
  description?: string
  value?: number
  format?: 'number' | 'currency'
  loading?: boolean
}>(), {
  format: 'number',
  loading: false,
})

const showDescription = props.description !== undefined && props.description.length > 0
const valueFormatted = computed(() => {
  if (props.value === undefined || Number.isNaN(props.value)) {
    return '-'
  }

  if (props.format === 'currency') {
    return formatCurrency(props.value, true)
  }
  return formatNumber(props.value)
})
</script>

<template>
  <div class="relative flex justify-between rounded-md border border-stroke bg-white py-2 px-3 shadow-md overflow-hidden dark:border-dark-strokedark dark:bg-dark-boxdark">
    <div class="flex items-center space-x-2">
      <slot name="icon"></slot>
      <div class="">
        <div class="">{{ label }}</div>
        <div v-if="showDescription" class="text-xs italic text-gray-700">{{ description }}</div>
      </div>
    </div>
    <div v-if="!loading" class="flex items-center font-bold">{{ valueFormatted }}</div>
    <div v-else class="flex items-center">
      <DotLoader />
      <!-- <svg class="transition" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="200" height="200" style="shape-rendering: auto; display: block; background: rgb(255, 255, 255);">
        <g data-idx="1">
          <g transform="matrix(1,0,0,1,20,50)" data-idx="2">
            <circle fill="#219ebc" r="6" cy="0" cx="0" data-idx="3" transform="matrix(0.7092312574386597,0,0,0.7092312574386597,0,0)"></circle>
          </g>
          <g transform="matrix(1,0,0,1,40,50)" data-idx="5">
            <circle fill="#023047" r="6" cy="0" cx="0" data-idx="6" transform="matrix(0.9677980542182922,0,0,0.9677980542182922,0,0)"></circle>
          </g>
          <g transform="matrix(1,0,0,1,60,50)" data-idx="8">
            <circle fill="#ffb703" r="6" cy="0" cx="0" data-idx="9" transform="matrix(0.9320967197418213,0,0,0.9320967197418213,0,0)"></circle>
          </g>
          <g transform="matrix(1,0,0,1,80,50)" data-idx="11">
            <circle fill="#fb8500" r="6" cy="0" cx="0" data-idx="12" transform="matrix(0.6416424512863159,0,0,0.6416424512863159,0,0)"></circle>
          </g>
          <g data-idx="14"></g>
        </g>
      </svg> -->
    </div>
  </div>
</template>