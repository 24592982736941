import http from '@http/apiProvider'

import type { FacebookAdMockupConfiguration } from '@components/facebook_ads/facebook_ad_mockup/types'

export type IQueryFacebookStatsForDashboardParams = {
  facebookAdClientId: number
  startDate: string
  endDate: string
}

type IChartNamedAreaDataset = {
  name: string,
  impressions: number,
  reach: number,
  spend: number,
  clicks: number,
  cpc: number,
  cpm: number,
}

export type IChartNamedAreasDataset = {
  name: string
  data: Array<IChartNamedAreaDataset>
  max?: number
  total: number
}

type IChartDataset = {
  name: string
  data: Array<number>
  max?: number
  total: number
}

export type IQueryFacebookStatsForDashboardResponse = {
  totalImpressions: number
  totalClicks: number
  totalClicksToWebsite: number
  totalSpend: number
  totalCpc: number
  labels: Array<string>
  datasets: {
    impressions: IChartDataset
    reach: IChartDataset
    spend: IChartDataset
    clicks: IChartDataset
    clicksToWebsite: IChartDataset
    videoWatches: IChartDataset
    cpc: IChartDataset
    cpm: IChartDataset
    namedAreas: IChartNamedAreasDataset
  }
  adMockupConfigurations: Array<FacebookAdMockupConfiguration>
}
export async function queryFacebookStatsForDashboard(params: IQueryFacebookStatsForDashboardParams) {
  const response = await http.get(`/facebook/stats.json`, { params })
  return response.data as IQueryFacebookStatsForDashboardResponse
}

export type IQueryFacebookConceptStatsResponse = {
  allTime: {
    impressions: number
    clicks: number
    spend: number
    cpc: number
    cpm: number
  },
  yearToDate: {
    impressions: number
    clicks: number
    spend: number
    cpc: number
    cpm: number
  },
  lastMonth: {
    impressions: number
    clicks: number
    spend: number
    cpc: number
    cpm: number
  },
}

export async function queryFacebookConceptStats (conceptId: number) {
  const response = await http.get(`/facebook/concept_stats.json`, { params: { conceptId } })
  return response.data as IQueryFacebookConceptStatsResponse
}