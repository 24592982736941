<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue';
import { VueFinalModal, useModal } from 'vue-final-modal';
import type { IAccountDetailsParams } from '@http/User'
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void
  (event: 'confirm', user: IAccountDetailsParams): void;
}>();

const props = defineProps<{
  resourceType: string
  resourceId: number
}>();

const firstName = ref('');
const lastName = ref('');
const email = ref('');

const addUser = () => {
  emit('confirm', {
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    resourceType: props.resourceType,
    resourceId: props.resourceId,
  });

  firstName.value = '';
  lastName.value = '';
  email.value = '';
};

const closeModal = () => {
  firstName.value = '';
  lastName.value = '';
  email.value = '';

  emit('update:modelValue', false);
};
</script>



<template>
  <VueFinalModal
    class="confirm-modal"
    content-class="confirm-modal-content"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
  >
    <h1>Add User:</h1>
    <form @submit.prevent="addUser" class="user-form">
      <div>
        <label for="firstName">First Name:</label>
        <input id="firstName" v-model="firstName" type="text" required>
      </div>
      <div>
        <label for="lastName">Last Name:</label>
        <input id="lastName" v-model="lastName" type="text" required>
      </div>
      <div>
        <label for="email">Email:</label>
        <input id="email" v-model="email" type="email" required>
      </div>
      <div style="display: flex; justify-content: flex-end;">
        <button type="submit" class="save-btn">Save</button>
        <button type="button" @click="closeModal" class="close-btn">Close</button>
      </div>
    </form>
  </VueFinalModal>
</template>


<style>
.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
  width: 500px;
}
.confirm-modal-content > * + * {
  margin-top: 0.5rem;
}
.confirm-modal-content h1 {
  font-size: 1.375rem;
}
.confirm-modal-content button {
  margin-top: 1rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}
.confirm-modal-content input,
.confirm-modal-content label {
  display: block;
  width: 100%;
}
.confirm-modal-content input {
  padding: 0.5rem;
  margin-top: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}
.dark .confirm-modal-content {
  background: #333;
  color: #fff;
}
.confirm-modal-content .close-btn {
  background-color: red;
  margin-left: 0.5rem; /* Adds spacing between the buttons */
}

.confirm-modal-content .save-btn {
  background-color: #007bff;
}
</style>

