<script setup lang="ts">
import { onMounted } from "vue"
import { v4 as uuid } from "uuid"
import ApexCharts from "apexcharts"

type SparklineOptions = {
  yMax?: number
}

const props = defineProps<{
  labels: Array<string>
  name: string
  data: Array<number>
  options: SparklineOptions
  class?: string
  width?: number
  height?: number
}>()


const elementId = uuid()
const classes = props.class ?? ''

const chart01 = () => {
  const options = {
    series: [
      {
        name: props.name,
        data: props.data,
      },
    ],
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["#FB8500", "#80CAEE"],
    chart: {
      fontFamily: "Satoshi, sans-serif",
      ...props.height && { height: props.height },
      ...props.width && { width: props.width },
      dropShadow: {
        enabled: true,
        color: "#FFB703",
        top: 10,
        blur: 4,
        left: 0,
        opacity: 0.1,
      },

      toolbar: {
        show: false,
      },

      sparkline: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          chart: {
            height: 300,
          },
        },
      },
      {
        breakpoint: 1366,
        options: {
          chart: {
            height: 350,
          },
        },
      },
    ],
    stroke: {
      width: [2, 2],
      curve: "straight",
    },

    labels: {
      show: false,
      position: "top",
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 3,
      colors: "#fff",
      strokeColors: ["#FB8500", "#023047"],
      strokeWidth: 1,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      hover: {
        size: undefined,
        sizeOffset: 5,
      },
    },
    xaxis: {
      type: "category",
      categories: props.labels,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: "0px",
        },
      },
      min: 0,
      max: props.options?.yMax,
      labels: {
        formatter: function (value: number) {
          return value.toLocaleString() // This will format the number with commas
        },
      },
    },
  };

  const chartSelector = document.getElementById(elementId);
  if (chartSelector) {
    const chartOne = new ApexCharts(
      chartSelector,
      options
    );
    chartOne.render()
  }
};

onMounted(() => {
  chart01()
})
</script>

<template>
  <div :id="elementId" :class="classes"></div>
</template>