<script setup lang="ts">
import { ref } from 'vue'
import StripeSubscriptionModal from './StripeSubscriptionModal.vue'
import { ModalsContainer } from 'vue-final-modal'

const props = defineProps<{ subscription: any }>()

const modalState = ref(false)

const openSubscriptionModal = () => {
  modalState.value = true
}
</script>

<template>
  <div>
    <template v-if="subscription">
      <span
        class="block px-3 py-1 text-sm leading-6 text-gray-700 hover:bg-gray-100 hover:text-gray-900"
      @click="openSubscriptionModal"
    >
        View Subscription
      </span>
      <StripeSubscriptionModal v-model="modalState" :subscription="subscription" />
    </template>
    <template v-else>
      <span class="block px-3 py-1 text-sm leading-6 text-gray-700 hover:bg-gray-100 hover:text-gray-900">
        No Active Stripe Subscription
      </span>
    </template>
    <ModalsContainer />
  </div>
</template>
