<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { ModalsContainer, useModal } from 'vue-final-modal';
import AddUserModal from './AddUser.vue';
import { createUser } from '@http/User'
import type { IAccountDetailsParams } from '@http/User'

type User = {
  id: number;
  fullname: string;
  email: string;
  currentSignin?: string;
  lastSignin?: string;
  role: string;
};

const props = defineProps<{
  users: User[]
  resourceType: string
  resourceId: number
}>();

const users = ref(props.users);

const modalState = ref(false)
const openModal = (event: Event) => {
  modalState.value = true 
  event.stopPropagation()
}

const saveNewUser = async (data: IAccountDetailsParams) => {
  const result = await createUser(data as IAccountDetailsParams)
  modalState.value = false
  users.value.push(result as User)
}
</script>

<template>
  <div>
    <div class="px-4 sm:px-0" style="display: flex; justify-content: space-between; align-items: center;">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">Users</h1>
        <p class="mt-1 text-sm leading-6 text-gray-600">A list of all users in this organization.</p>
      </div>
      <button style="height: 36px;" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="openModal">Add User</button>
    </div>

    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ID</th>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Current Sign In</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last Sign In</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="user in users" :key="user.id">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">{{ user.id }}</td>
                  <td class="whitespace-nowrap py-4 pl-4 text-sm text-gray-900">{{ user.fullname }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.email }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.currentSignin }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.lastSignin }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.role }}</td>
                  <td class="relative flex justify-end space-x-6 whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AddUserModal v-model="modalState" @confirm="saveNewUser" :resourceType="resourceType" :resourceId="resourceId" />
  <ModalsContainer />
</template>
