<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { ref } from 'vue'
import { fundAdStrategyExecution } from '@http/AdStrategyExecution'  // Adjust the import path as needed

const props = defineProps<{ modelValue: boolean; ase: any }>()
const emit = defineEmits(['update:modelValue', 'fund'])

const modalState = ref(false)
const fundingAmount = ref('')
const showOverfundWarning = ref(false)
const isFundDisabled = ref(true)
const overfundAmount = ref(0)

const closeModal = () => {
  emit('update:modelValue', false)
}

const formatCurrency = (amount: number | null) => {
  const validAmount = amount !== null ? Number(amount) : 0
  return `$${validAmount.toFixed(2)}`
}

const validateAmount = () => {
  const amount = parseFloat(fundingAmount.value.replace(/[^0-9.]/g, ''))

  if (isNaN(amount) || amount <= 0) {
    isFundDisabled.value = true
    showOverfundWarning.value = false
    return
  }

  const remainingFundingTotal = Math.abs(Number(props.ase.remaining_funding_total))

  if (amount > remainingFundingTotal) {
    showOverfundWarning.value = true

    overfundAmount.value = parseFloat((amount - remainingFundingTotal).toFixed(2));
    isFundDisabled.value = false
  } else {
    showOverfundWarning.value = false
    isFundDisabled.value = false
  }
}

const fund = async () => {
  const amount = parseFloat(fundingAmount.value.replace(/[^0-9.]/g, ''))
  if (!isNaN(amount) && amount > 0) {
    try {
      const params = {
        adStrategyExecutionId: props.ase.id,
        amount: amount
      }

      const response = await fundAdStrategyExecution(params)
      console.log('Funding successful:', response)

      closeModal()
    } catch (error) {
      console.error('Error funding ASE:', error)
    }
  }
}
</script>

<template>
  <VueFinalModal
    v-model="props.modelValue"
    class="flex justify-center items-center"
    content-class="flex flex-col p-4 sm:p-8 bg-white rounded-lg w-[50%] h-auto relative"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    clickToClose
    @update:modelValue="closeModal"
  >
    <div class="sticky top-0 right-0 flex justify-end bg-white z-10">
      <button type="button" @click="closeModal" class="text-gray-500 text-2xl">×</button>
    </div>

    <div class="flex flex-col overflow-y-auto space-y-4">
      <div>
        <h2 class="text-lg font-semibold text-gray-900">Manual Fund</h2>
        <hr class="border-gray-300 mb-4" />

        <div class="mb-4 text-gray-700">
          <p><strong>Required Funding Level:</strong> {{ formatCurrency(props.ase.required_funding_total) }}</p>
          <p><strong>Current Funding Total:</strong> {{ formatCurrency(props.ase.current_funding_level) }}</p>
          <p><strong>Remaining Funding Total:</strong> {{ formatCurrency(props.ase.remaining_funding_total) }}</p>
        </div>
        <hr class="border-gray-300" />

        <div class="mt-4">
          <label for="fundingAmount" class="block text-sm font-medium text-gray-700">
            Enter Funding Amount
          </label>
          <input
            v-model="fundingAmount"
            type="text"
            id="fundingAmount"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            @input="validateAmount"
            placeholder="$0.00"
          />
          <p v-if="showOverfundWarning" class="text-sm text-orange-500 mt-1">
            Funding this amount will overfund the ASE by ${{ overfundAmount }}.
          </p>
        </div>

        <div class="flex justify-end mt-4">
          <button
            :disabled="isFundDisabled"
            @click="fund"
            class="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50"
          >
            Fund
          </button>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<style scoped>
.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 300px;
}
</style>
