import { Controller } from '@hotwired/stimulus'
import tippy, { type Instance, animateFill } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';

export default class TooltipController extends Controller {
  static values = { text: String } // We could receive a config object here, to customize the tooltip

  tippyInstance!: Instance
  textValue!: string

  connect() {
    this.tippyInstance = tippy(this.element, {
      allowHTML: true,
      content: this.data.get('textValue')?.replace(/\\/g, "") || '',
      trigger: 'mouseenter focus',
      maxWidth: 'none',
      animateFill: true,
      theme: 'hyperlcl',
      plugins: [animateFill],
    })
  }

  disconnect() {
    this.tippyInstance.destroy()
  }
}
