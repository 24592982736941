import BaseModel from '@models/Model'

export interface IBusiness {
  id: number
  name: string
  nickname: string

  conceptId: number
  conceptBannerLogoUrl?: string
}

class Business extends BaseModel {
  NAME = 'Business'

  id!: number
  name!: string
  nickname!: string

  conceptId!: number
  conceptBannerLogoUrl?: string

  constructor (data: IBusiness) {
    Object.keys(data).forEach(key => { (data as IIndexable)[key] = (data as IIndexable)[key] === null ? undefined : (data as IIndexable)[key] })
    super(data)
    Object.assign(this, data)
  }
}

export default Business